export const translations = {
  ko: {
    loading: 'Loading...',
    invalidStudent: '잘못된 학생 ID입니다.',
    studentNotFound: '학생 정보를 찾을 수 없습니다.',
    learningStatus: '님의 학습 현황',
    enterClass: '수업 입장하기',
    totalHours: '이번달 수업 시간',
    totalSessions: '이번달 수업 횟수',
    avgLength: '평균 수업 시간',
    lastMonth: '지난달 수업',
    sessions: '회',
    hours: '시간',
    minutes: '분',
    recentHomework: '최근 숙제',
    homework: '숙제',
    nextClass: '다음 수업:',
    undecided: '미정',
    noHomework: '이번 수업에는 숙제가 없습니다.',
    upcomingSessions: '예정된 수업',
    today: '오늘',
    tomorrow: '내일',
    topics: '📚 수업 내용:',
    homeworkLabel: '📝 숙제:',
    classesCounted: '회 수업',
    materials: '학습 자료',
    downloadPdf: 'PDF 다운로드',
    downloadError: 'PDF 다운로드 중 오류가 발생했습니다.',
    noLogs: '아직 수업 기록이 없습니다.',
  },
  en: {
    loading: 'Loading...',
    invalidStudent: 'Invalid student ID.',
    studentNotFound: 'Student information not found.',
    learningStatus: "'s Learning Progress",
    enterClass: 'Enter Class',
    totalHours: 'This Month Hours',
    totalSessions: 'This Month Sessions',
    avgLength: 'Avg. Length',
    lastMonth: 'Last Month',
    sessions: 'sessions',
    hours: 'hours',
    minutes: 'min',
    recentHomework: 'Recent Homework',
    homework: 'Homework',
    nextClass: 'Next Class:',
    undecided: 'TBD',
    noHomework: 'No homework assigned for this class.',
    upcomingSessions: 'Upcoming Sessions',
    today: 'Today',
    tomorrow: 'Tomorrow',
    topics: '📚 Topics:',
    homeworkLabel: '📝 Homework:',
    classesCounted: 'classes',
    materials: 'Materials',
    downloadPdf: 'Download PDF',
    downloadError: 'Error downloading PDF',
    noLogs: 'No class records yet.',
  }
}; 