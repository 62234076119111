export const translations = {
  ko: {
    studentManagement: '학생 관리',
    viewProgress: '진도 현황 보기',
    share: '🔗 공유',
    newStudentName: '새 학생 이름',
    subjects: '과목 (예: 수학, 영어)',
    addStudent: '학생 추가',
    selectStudent: '학생 선택',
    classRecord: '수업 기록',
    classSchedule: '수업 일정',
    memo: '메모 (선택사항)',
    scheduleClass: '수업 일정 등록',
    selectFromScheduled: '예정된 수업에서 선택...',
    noScheduledClasses: '예정된 수업이 없습니다.',
    classContent: '수업 내용',
    homework: '숙제',
    saveClassRecord: '수업 기록 저장',
    recentClassRecords: '최근 수업 기록',
    classContent_label: '수업 내용:',
    homework_label: '숙제:',
    backToLog: '← 수업 기록으로 돌아가기',
    copyShareLink: '🔗 공유 링크 복사',
    requiredFields: '필수 항목을 모두 입력해주세요.',
    scheduleSuccess: '수업 일정이 등록되었습니다.',
    scheduleError: '일정 등록 중 오류가 발생했습니다.',
    linkCopied: '공유 링크가 클립보드에 복사되었습니다.',
    attachPdf: 'PDF 파일 첨부 (선택사항)',
    uploading: 'PDF 업로드 중...',
    viewAttachedPdf: '첨부된 PDF 보기',
    pdfUploadSuccess: 'PDF 파일이 성공적으로 업로드되었습니다.',
    pdfUploadError: 'PDF 업로드 중 오류가 발생했습니다.',
    invalidFileType: 'PDF 파일만 업로드할 수 있습니다.',
    googleLoginError: 'Google 로그인 중 오류가 발생했습니다. 팝업이 차단되지 않았는지 확인해주세요.',
    someFilesInvalid: 'PDF 형식이 아닌 파일은 제외되었습니다.',
    startingUpload: '업로드 시작',
    preparingFile: '파일 준비 중',
    uploadingFile: '파일 업로드 중',
    configuringPermissions: '권한 설정 중',
    generatingLink: '공유 링크 생성 중',
    uploadComplete: '업로드 완료',
  },
  en: {
    studentManagement: 'Student Management',
    viewProgress: 'View Progress',
    share: '🔗 Share',
    newStudentName: 'New Student Name',
    subjects: 'Subjects (e.g., Math, English)',
    addStudent: 'Add Student',
    selectStudent: 'Select Student',
    classRecord: 'Class Record',
    classSchedule: 'Class Schedule',
    memo: 'Memo (Optional)',
    scheduleClass: 'Schedule Class',
    selectFromScheduled: 'Select from scheduled classes...',
    noScheduledClasses: 'No scheduled classes.',
    classContent: 'Class Content',
    homework: 'Homework',
    saveClassRecord: 'Save Class Record',
    recentClassRecords: 'Recent Class Records',
    classContent_label: 'Class Content:',
    homework_label: 'Homework:',
    backToLog: '← Back to Class Records',
    copyShareLink: '🔗 Copy Share Link',
    requiredFields: 'Please fill in all required fields.',
    scheduleSuccess: 'Class has been scheduled successfully.',
    scheduleError: 'An error occurred while scheduling the class.',
    linkCopied: 'Share link has been copied to clipboard.',
    attachPdf: 'Attach PDF (Optional)',
    uploading: 'Uploading PDF...',
    viewAttachedPdf: 'View Attached PDF',
    pdfUploadSuccess: 'PDF file uploaded successfully.',
    pdfUploadError: 'Error uploading PDF file.',
    invalidFileType: 'Only PDF files are allowed.',
    googleLoginError: 'Error during Google login. Please check if popups are not blocked.',
    someFilesInvalid: 'Files that are not in PDF format have been excluded.',
    startingUpload: 'Starting upload',
    preparingFile: 'Preparing file',
    uploadingFile: 'Uploading file',
    configuringPermissions: 'Configuring permissions',
    generatingLink: 'Generating share link',
    uploadComplete: 'Upload complete',
  }
}; 